import clsx from 'clsx';

interface IProps {
  user: User;
  className?: string;
}

const Avatar = ({ user, className }: IProps) => {
  return (
    <img
      src={user.avatar ?? `https://ui-avatars.com/api?name=${user.first_name}`}
      alt={user.first_name}
      className={clsx('avatar', className)}
    />
  );
};

export default Avatar;

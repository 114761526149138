import type { z } from 'zod';

import { paginable } from './paginable';
import { Post } from './post';

export namespace Posts {
  export const schema = paginable(Post.schema);
}

export type PostsType = z.infer<typeof Posts.schema>;

import { Link } from 'react-router-dom';

import type { PostType } from '@/types';

const FeaturePost = ({ post }: { post: PostType }) => {
  return (
    <div className="feature">
      <div className="feature__cover">
        <Link to={`/blog/post/${post.id}`}>
          <img src={post.cover} alt={post.title} />
        </Link>
      </div>

      <div className="feature__info">
        <Link to={`/blog/post/${post.id}`}>{post.title}</Link>
        <div>
          <Link to={`/blog/category/${post.category.id}`}>
            {post.category.name}
          </Link>
          <span>{post.created_at}</span>
        </div>
        <p>{post.short_description}</p>
      </div>
    </div>
  );
};

export default FeaturePost;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import fa from './locales/fa.json';

i18n.use(initReactI18next).init({
  lng: 'fa',
  fallbackLng: 'en',
  defaultNS: 'global',
  resources: { en, fa }
});

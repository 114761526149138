import { Logo } from '../atoms';
import { Profile } from '../molecules';

const Header = () => {
  return (
    <header className="header">
      <Profile />

      <Logo />
    </header>
  );
};

export default Header;

import { z } from 'zod';

/**
 * Validates the redux endpoint's response object against the provided Zod schema.
 *
 * @template T - The Zod schema type to validate against.
 * @param {T} schema - The Zod schema to use for validation.
 * @returns {(response: unknown) => z.infer<T>} A function that takes a response object and validates it against the schema.
 */
export const validate = <T extends z.ZodTypeAny>(schema: T) => {
  return (response: unknown) => {
    try {
      return schema.parse(response);
    } catch (e) {
      if (e instanceof z.ZodError) {
        console.error(e.issues);
      }

      return undefined;
    }
  };
};

import { z } from 'zod';

export namespace User {
  export const schema = z.object({
    id: z.number(),
    telegram_id: z.number(),
    token: z.string(),
    avatar: z.string().url().nullish(),
    username: z.string(),
    first_name: z.string(),
    last_name: z.string().nullish(),
    language_code: z.string(),
    is_bot: z.boolean(),
    bot_can_message: z.boolean(),
    created_at: z.string()
  });
}

export type UserType = z.infer<typeof User.schema>;

import { useCallback, useRef, useState } from 'react';

const useInfiniteScroll = (
  {
    isReady,
    hasMore,
    next
  }: {
    isReady: boolean;
    hasMore: boolean;
    next: (arg: number) => void;
  },
  deps: readonly unknown[]
) => {
  const [nextPage, setPage] = useState(2);
  const observer = useRef<IntersectionObserver>();

  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (!isReady) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          next(nextPage);

          setPage(nextPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [isReady, hasMore, ...deps]
  );

  return {
    ref: lastElementRef
  };
};

export default useInfiniteScroll;

import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Breadcrumb } from '@/components/atoms';
import { BlogApi } from '@/redux/api';
import type { PostType } from '@/types';

const Post = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data: post }: { data?: PostType } = BlogApi.useGetPostQuery({ id });

  return post ? (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/blog">{t('blog')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/blog/category/${post.category.id}`}>
            {post.category.name}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="post">
        <p className="post__short-description">{post.short_description}</p>

        <div className="post__info">
          <img src={post.cover} alt={post.title} className="post__cover" />
          <div>
            <h1 className="post__title">{post.title}</h1>

            <div>
              <Link to={`/blog/category/${post.category.id}`}>
                {post.category.name}
              </Link>
              <span>{post.created_at}</span>
            </div>
          </div>
        </div>

        {/* <Share /> */}

        <div
          className="post__content"
          dangerouslySetInnerHTML={{ __html: post.body }}
        />
      </div>
    </>
  ) : null;
};

export default Post;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PostCard } from '@/components/molecules';
import { useInfiniteScroll } from '@/hooks';
import { BlogApi } from '@/redux/api';
import type { PostType } from '@/types';

const Category = () => {
  const { t } = useTranslation();
  const [getPosts, result] = BlogApi.useLazyGetPostsQuery();
  const [posts, setPosts] = useState(result.data);
  const { ref } = useInfiniteScroll(
    {
      isReady: result.isSuccess,
      hasMore: !!posts?.links.next_page_url,
      next: (page: number) => {
        getPosts({ page });
      }
    },
    [posts?.links.next_page_url]
  );

  useEffect(() => {
    getPosts({});
  }, []);

  useEffect(() => {
    if (!result.data) return;

    const { data, links } = result.data;

    setPosts({
      data: [...(posts?.data ?? []), ...data],
      links
    });
  }, [result.data]);

  return posts?.data?.length ? (
    <div className="post-list">
      {posts?.data?.map((post: PostType, i: number) => (
        <PostCard
          key={post.id}
          ref={posts?.data?.length === i + 1 ? ref : null}
          post={post}
        />
      ))}
      {result.isFetching || result.isLoading ? (
        <div className="bg-[--secondary-color] rounded-lg overflow-hidden">
          <div className="w-full h-60 bg-[#bdbdbd] rounded-lg" />
          <div className="px-2 py-3">
            <div className="flex justify-between h-3">
              <span className="w-28 bg-[#bdbdbd]" />
              <span className="w-20 bg-[#bdbdbd]" />
            </div>
            <div className="w-2/3 h-4 mt-4 bg-[#bdbdbd]" />
            <div className="w-full h-10 mt-2 bg-[#bdbdbd]" />
          </div>
        </div>
      ) : null}
    </div>
  ) : (
    <div>{t('empty_category')}</div>
  );
};

export default Category;

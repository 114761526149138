import clsx from 'clsx';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

interface IProps
  extends React.PropsWithChildren<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  > {
  className?: string;
}

const PrevButton = ({ children, ...props }: IProps) => {
  return (
    <button type="button" className="slider-prev-btn" {...props}>
      <HiOutlineChevronLeft />
      {children}
    </button>
  );
};

const NextButton = ({ children, ...props }: IProps) => {
  return (
    <button type="button" className="slider-next-btn" {...props}>
      <HiOutlineChevronRight />
      {children}
    </button>
  );
};

const DotButton = ({ className, children, ...props }: IProps) => {
  return (
    <button
      type="button"
      className={clsx('slider-dot-btn', className)}
      {...props}
    >
      {children}
    </button>
  );
};

export { DotButton, NextButton, PrevButton };

import { Link } from 'react-router-dom';

import { BaseApi } from '@/redux/api';

import { Slider } from '../molecules';
import { FeaturesList, GamesList } from '../organisms';

const Home = () => {
  const { data: slides = {} } = BaseApi.useGetSlidesQuery(undefined);

  return (
    <div className="container">
      {slides?.length ? (
        <Slider
          options={{
            loop: true,
            autoplay: true,
            control: { dots: true, buttons: true }
          }}
          className="mb-3"
        >
          {slides?.map((slide: Record<string, string>) => (
            <Slider.Item key={slide.id}>
              <Link
                to={slide.link}
                target={slide.type === 'external_link' ? '_blank' : '_self'}
              >
                <img src={slide.image} alt="" />
              </Link>
            </Slider.Item>
          ))}
        </Slider>
      ) : null}

      <GamesList />

      <FeaturesList />
    </div>
  );
};

export default Home;

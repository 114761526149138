import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiHandsClappingFill } from 'react-icons/pi';

import { Dialog } from '../atoms';

interface IProps {
  won: {
    message: string;
    data: Record<string, string | number>;
    additional: Record<string, string | number>;
  };
}

interface IParticle {
  x: number;
  y: number;
  r: number;
  d: number;
  color: string;
  tilt: number;
  tiltAngleIncremental: number;
  tiltAngle: number;
  draw: () => void;
}

const Celebrate = ({ won }: IProps) => {
  const { t } = useTranslation();
  const [open, setDialog] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const handleCopy = (e: React.BaseSyntheticEvent) => {
    const { target } = e;
    const defaultIcon = target.querySelector('#default-icon');
    const successIcon = target.querySelector('#success-icon');

    defaultIcon.classList.add('hidden');
    successIcon.classList.remove('hidden');

    navigator.clipboard.writeText(won.additional.prize_code as string);

    setTimeout(() => {
      successIcon.classList.add('hidden');
      defaultIcon.classList.remove('hidden');
    }, 1500);
  };

  useEffect(() => {
    const W = window.innerWidth;
    const H = window.innerHeight;
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (!context || !canvas) return;

    const maxConfettis = 50;
    const particles: IParticle[] = [];

    const possibleColors = [
      'DodgerBlue',
      'OliveDrab',
      'Gold',
      'Pink',
      'SlateBlue',
      'LightBlue',
      'Gold',
      'Violet',
      'PaleGreen',
      'SteelBlue',
      'SandyBrown',
      'Chocolate',
      'Crimson'
    ];

    const randomFromTo = (from: number, to: number): number =>
      Math.floor(Math.random() * (to - from + 1) + from);

    function createConfettiParticle(): IParticle {
      return {
        x: Math.random() * W,
        y: Math.random() * H - H,
        r: randomFromTo(11, 33),
        d: Math.random() * maxConfettis + 11,
        color:
          possibleColors[Math.floor(Math.random() * possibleColors.length)],
        tilt: Math.floor(Math.random() * 33) - 11,
        tiltAngleIncremental: Math.random() * 0.07 + 0.05,
        tiltAngle: 0,
        draw() {
          context!.beginPath();
          context!.lineWidth = this!.r / 2;
          context!.strokeStyle = this!.color;
          context!.moveTo(this!.x + this!.tilt + this!.r / 3, this!.y);
          context!.lineTo(
            this!.x + this!.tilt,
            this!.y + this!.tilt + this!.r / 5
          );
          return context!.stroke();
        }
      };
    }

    function Draw() {
      requestAnimationFrame(Draw);
      context?.clearRect(0, 0, W, window.innerHeight);

      for (let i = 0; i < maxConfettis; i += 1) {
        particles[i].draw();
      }

      let particle: IParticle;
      for (let i = 0; i < maxConfettis; i += 1) {
        particle = particles[i];

        particle.tiltAngle += particle.tiltAngleIncremental;
        particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
        particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

        if (particle.x > W + 30 || particle.x < -30 || particle.y > H) {
          particle.x = Math.random() * W;
          particle.y = -30;
          particle.tilt = Math.floor(Math.random() * 10) - 20;
        }
      }
    }

    for (let i = 0; i < maxConfettis; i += 1) {
      particles.push(createConfettiParticle());
    }

    canvas.width = W;
    canvas.height = H;

    Draw();
  }, []);

  return (
    <>
      {open ? <canvas ref={canvasRef} className="celebrate" /> : null}
      <Dialog open={open} onClose={setDialog}>
        <Dialog.Icon>
          <PiHandsClappingFill className="w-8 h-8" />
        </Dialog.Icon>

        <Dialog.Title>{won.message}</Dialog.Title>

        <Dialog.Content>
          <div className="w-full min-w-[14rem] max-w-[20rem]">
            <div className="relative">
              <input
                type="text"
                className="col-span-6 bg-[--secondary-color] border border-[--border-color] text-[--text-color] text-sm rounded-lg block w-full p-2.5"
                value={won.additional.prize_code}
                disabled
                readOnly
              />
              <button
                type="button"
                className="absolute end-2 top-1/2 -translate-y-1/2 text-[--text-color] hover:bg-[--primary-color] rounded-lg p-2 inline-flex items-center justify-center"
                onClick={handleCopy}
              >
                <span className="sr-only">Clipboard</span>
                <span id="default-icon" className="pointer-events-none">
                  <svg
                    className="w-3.5 h-3.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 20"
                  >
                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                  </svg>
                </span>
                <span
                  id="success-icon"
                  className="hidden inline-flex items-center pointer-events-none"
                >
                  <svg
                    className="w-3.5 h-3.5 text-blue-700 dark:text-blue-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </Dialog.Content>

        <Dialog.Action className="mt-4">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-lg bg-indigo-600 p-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => setDialog(false)}
          >
            {t('back_to_game')}
          </button>
        </Dialog.Action>
      </Dialog>
    </>
  );
};

export default memo(Celebrate);

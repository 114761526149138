import { HiTable } from 'react-icons/hi';

import { GameApi } from '@/redux/api';

import { Spinner } from '../atoms';
import { BetHistory, SlideOver } from '../molecules';

interface IProps {
  gameId: number;
  scheduleId: number;
}

const BestBet = ({ gameId, scheduleId }: IProps) => {
  const [getWinners, result] = GameApi.useGetWinnersMutation();

  const handleClick = () => {
    getWinners({ gameId, scheduleId });
  };

  return (
    <SlideOver direction="btt">
      <SlideOver.Trigger onClick={handleClick}>
        <button
          type="button"
          className="absolute bottom-2 left-2 bg-[--secondary-color] p-1.5 rounded-3xl border border-[--border-color] shadow-[0_0_4px_var(--border-color)]"
        >
          <span className="sr-only">Best bet</span>
          <HiTable className="h-5 w-5" />
        </button>
      </SlideOver.Trigger>
      <SlideOver.Content className="!h-[calc(100vh_-_var(--header-height))]">
        {result.isSuccess ? (
          <BetHistory histories={result.data} />
        ) : (
          <Spinner />
        )}
      </SlideOver.Content>
    </SlideOver>
  );
};

export default BestBet;

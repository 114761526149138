import LoadingGif from '@/assets/img/loader.gif';

const Loading = () => {
  return (
    <div className="fixed top-0 left-0 flex justify-center items-center w-screen h-screen bg-[--secondary-color] z-[60]">
      <img src={LoadingGif} alt="" />
    </div>
  );
};

export default Loading;

import { z } from 'zod';

export namespace Post {
  export const schema = z.object({
    id: z.coerce.number(),
    title: z.string(),
    description: z.string(),
    short_description: z.string(),
    body: z.string(),
    cover: z.string(), // .url()
    category: z.object({
      id: z.coerce.number(),
      name: z.string(),
      cover: z.string().optional() // FIXME: URL should be used instead of optional
    }),
    updated_at: z.string(),
    created_at: z.string()
  });
}

export type PostType = z.infer<typeof Post.schema>;

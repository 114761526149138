import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import ContextProvider from './contexts';
import routes from './routes';

import './styles/global.css';

import './i18n';
import './sentry';

const router = createBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById('app')!).render(
  <React.StrictMode>
    <ContextProvider>
      <RouterProvider router={router} />
    </ContextProvider>
  </React.StrictMode>
);

import { createApi } from '@reduxjs/toolkit/query/react';

import { validate } from '@/libs';
import { Category, Post, Posts } from '@/types';

import axiosBaseQuery from './axios-base-query';

export const api = createApi({
  reducerPath: 'blogApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getPosts: build.query({
      query: ({ page = 1 }) => ({ url: `/posts?page=${page}`, method: 'get' }),
      transformResponse: validate(Posts.schema)
    }),
    getFeaturePosts: build.query({
      query: () => ({ url: '/posts?feature', method: 'get' }),
      transformResponse: validate(Posts.schema)
    }),
    getCategory: build.query({
      query: ({ id, page = 1 }) => ({
        url: `/categories/${id}/posts?page=${page}`,
        method: 'get'
      }),
      transformResponse: validate(Category.schema)
    }),
    getPost: build.query({
      query: ({ id }) => ({ url: `/posts/${id}`, method: 'get' }),
      transformResponse: validate(Post.schema)
    })
  })
});

export const {
  reducer,
  middleware,
  useLazyGetPostsQuery,
  useGetFeaturePostsQuery,
  useLazyGetCategoryQuery,
  useGetPostQuery
} = api;

import type { z } from 'zod';

import { Game } from './game';
import { paginable } from './paginable';

export namespace Games {
  export const schema = paginable(Game.data);
}

export type GamesType = z.infer<typeof Games.schema>;

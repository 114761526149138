import type { AxiosError, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { toast } from 'react-toastify';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';

import { Env } from '@/libs';
import { store } from '@/store';

const axiosBaseQuery =
  (): BaseQueryFn<{
    url: string;
    method?: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  }> =>
  async (request) => {
    const token = store.getState().initial.user?.token;
    const headers = token
      ? { ...request.headers, Authorization: `Bearer ${token}` }
      : request.headers;

    try {
      const result = await axios({
        baseURL: Env.APP_API_URL,
        ...request,
        headers
      });

      return Object.keys(result.data).length > 1
        ? { data: result.data }
        : result.data;
    } catch (error) {
      const err = error as AxiosError;
      const status = err.response?.status;
      const data = err.response?.data || err;

      toast.error((data as { message: string }).message);

      window.Telegram.HapticFeedback?.notificationOccurred('error');

      return { error: { status, data } };
    }
  };

export default axiosBaseQuery;

import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { easeOutCirc } from 'easing-utils';
import { toast } from 'react-toastify';
import { Wheel as BaseWheel } from 'spin-wheel';

import Ding from '@/assets/audio/ding.mp3';
import Lose from '@/assets/audio/lose.mp3';
import Won from '@/assets/audio/won.mp3';
import OverlayImg from '@/assets/img/overlay.svg';
import WheelImg from '@/assets/img/wheel-central.png';
import { vibrate } from '@/libs';

const Wheel = ({ telegram, betResult, unlock, celebrate, game }: GameProps) => {
  const dingAudio = new Audio(Ding);
  const wonAudio = new Audio(Won);
  const loseAudio = new Audio(Lose);
  const spinDuration = 20000;
  const ref = useRef<HTMLDivElement>(null);
  const [wheel, setWheel] = useState<BaseWheel>();

  const initWheel = useCallback(() => {
    if (wheel && ref.current) {
      ref.current.innerHTML = '';
    }

    const { prizes } = game.additional.active_schedule;
    const colors = prizes.map((prize: Prize) => prize.color);

    const w = new BaseWheel(ref.current, {
      isInteractive: false,
      itemBackgroundColors: colors,
      rotationSpeedMax: 500,
      rotationResistance: -100,
      lineWidth: 1,
      radius: 0.84,
      itemLabelRadius: 0.93,
      itemLabelRadiusMax: 0.35,
      itemLabelRotation: 180,
      itemLabelFont: 'IranSans',
      itemLabelFontSizeMax: 18,
      itemLabelAlign: 'left',
      itemLabelColors: ['#fff'],
      itemLabelBaselineOffset: -0.07,
      lineColor: '#fff',
      image: WheelImg,
      overlayImage: OverlayImg,
      items: prizes
    });

    w.onCurrentIndexChange = () => {
      vibrate('step');
      dingAudio.play().catch(() => {});
    };

    setWheel(w);
  }, [game]);

  useEffect(() => {
    if (game) {
      initWheel();
    }
  }, [game]);

  useEffect(() => {
    if (betResult.isSuccess) {
      const { data } = betResult.data;

      const index = game.additional.active_schedule.prizes.findIndex(
        (prize: Prize) => prize.key === data.key
      );

      wheel?.spinToItem(index, spinDuration, false, 12, 1, easeOutCirc);

      setTimeout(() => {
        unlock();
        telegram.BackButton.show();

        if (data.is_win) {
          wonAudio.play().catch(() => {});
          celebrate();

          vibrate('win');
        } else {
          loseAudio.play().catch(() => {});

          toast.error(betResult.data.message);
          vibrate('lose');
        }

        telegram.MainButton.setParams({ color: '#33B249', is_active: true });
      }, spinDuration);
    }
  }, [betResult]);

  return <div className="game-container" ref={ref} />;
};

export default memo(Wheel);

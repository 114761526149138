import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import DiceBox from '@3d-dice/dice-box-threejs';

import Lose from '@/assets/audio/lose.mp3';
import Won from '@/assets/audio/won.mp3';
import { vibrate } from '@/libs';

const Dice = ({ telegram, betResult, unlock, celebrate }: GameProps) => {
  const wonAudio = new Audio(Won);
  const loseAudio = new Audio(Lose);
  const box = useRef<DiceBox>(null!);

  useEffect(() => {
    if (!box.current) {
      const Box = new DiceBox('#dice-game', {
        assetPath:
          !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
            ? '/src/assets/'
            : '/assets/',
        framerate: 1 / 75,
        sounds: false,
        volume: 100,
        color_spotlight: 0xefdfd5,
        shadows: true,
        theme_customColorset: {
          background: '#fe2a04',
          foreground: '#ffffff',
          texture: 'astral',
          material: 'plastic'
        },
        gravity_multiplier: 200,
        light_intensity: 0.7,
        iterationLimit: 2e8,
        baseScale: 85,
        strength: 4
      });
      Box.initialize();

      box.current = Box;
    }
  }, []);

  useEffect(() => {
    if (betResult?.isSuccess) {
      const { data, additional } = betResult.data;

      box.current.roll(`2dpip@${additional.dice_number.replaceAll('-', ',')}`);

      box.current.onRollComplete = () => {
        unlock();
        telegram.BackButton.show();

        if (data.is_win) {
          wonAudio.play().catch(() => {});
          celebrate();

          vibrate('win');
        } else {
          loseAudio.play().catch(() => {});

          toast.error(betResult.data.message);
          vibrate('lose');
        }

        telegram.MainButton.setParams({ color: '#33B249', is_active: true });
      };
    }
  }, [betResult]);

  return <div id="dice-game" className="game-container" />;
};

export default Dice;

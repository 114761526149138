import { z } from 'zod';

export const paginable = <T extends z.ZodTypeAny>(data: T) =>
  z.object({
    data: z.array(data),
    links: z.object({
      current_page: z.coerce.number(),
      first_page_url: z.any(),
      from: z.any(),
      last_page: z.any(),
      last_page_url: z.any(),
      links: z.any(),
      next_page_url: z.any(),
      path: z.any(),
      per_page: z.any(),
      prev_page_url: z.any(),
      to: z.any(),
      total: z.any()
    })
  });

import type { PayloadAction } from '@reduxjs/toolkit';

import { createSliceWithThunks } from '@/libs';

import { BaseApi } from '../api';

interface InitialState {
  loading: boolean;
  user: User | null;
}

const initialState: InitialState = {
  loading: true,
  user: null
};

const slice = createSliceWithThunks({
  name: 'initial',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<InitialState['user']>) => {
      state.user = action.payload;
    },
    hasLoading: (state, action: PayloadAction<InitialState['loading']>) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      BaseApi.api.endpoints.auth.matchFulfilled,
      (state, action) => {
        state.user = action.payload;
      }
    );
  },
  selectors: {
    getUser: (state) => state.user
  }
});

export const { setUser, hasLoading } = slice.actions;

export const { getUser } = slice.selectors;

export default slice.reducer;

import { useTelegram } from '@/hooks';

import { GamesList } from '../organisms';

const Games = () => {
  const telegram = useTelegram();

  telegram.BackButton.show();

  return (
    <div className="container">
      <GamesList />
    </div>
  );
};

export default Games;

import { useTranslation } from 'react-i18next';
import { BiLogoBlogger } from 'react-icons/bi';
import { HiHome, HiOutlineLogout, HiUser } from 'react-icons/hi';
import { PiGameControllerFill } from 'react-icons/pi';
import { NavLink } from 'react-router-dom';

import { useTelegram } from '@/hooks';

const BottomNav = () => {
  const { t } = useTranslation();
  const telegram = useTelegram();

  return (
    <div className="bottom-nav">
      <div>
        <button type="button" onClick={telegram.close}>
          <span>
            <HiOutlineLogout />
          </span>
          <span>{t('logout')}</span>
        </button>
      </div>

      <div>
        <NavLink to="/dashboard">
          <span>
            <HiUser />
          </span>
          <span>{t('accounts')}</span>
        </NavLink>
      </div>

      <div>
        <NavLink to="/games">
          <span>
            <PiGameControllerFill />
          </span>
          <span>{t('games')}</span>
        </NavLink>
      </div>

      <div>
        <NavLink to="/">
          <span>
            <HiHome />
          </span>
          <span>{t('home')}</span>
        </NavLink>
      </div>

      <div>
        <NavLink to="/blog">
          <span>
            <BiLogoBlogger />
          </span>
          <span>{t('blog')}</span>
        </NavLink>
      </div>
    </div>
  );
};

export default BottomNav;

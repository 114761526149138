import React from 'react';
import clsx from 'clsx';
import {
  Dialog as BaseDialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react';

interface IProps extends React.PropsWithChildren {
  open: boolean;
  onClose: (arg: boolean) => void;
}

interface IIconProps extends React.PropsWithChildren {
  className?: string;
}

interface ITitleProps extends React.PropsWithChildren {}

interface IContentProps extends React.PropsWithChildren {}

interface IActionProps extends React.PropsWithChildren {
  className?: string;
}

const Dialog = ({ open, children, onClose }: IProps) => {
  const childs: Record<string, React.ReactElement> = {};

  React.Children.forEach(children as React.ReactElement, (child) => {
    switch (child.type) {
      case Dialog.Icon:
        childs.icon = child;
        break;
      case Dialog.Title:
        childs.title = child;
        break;
      case Dialog.Content:
        childs.content = child;
        break;
      case Dialog.Action:
        childs.action = child;
        break;
      default:
        break;
    }
  });

  return (
    <Transition show={open}>
      <BaseDialog className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-[--primary-color] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {childs?.icon}
                  <div className="mt-3 text-center sm:mt-5">
                    {childs?.title}
                    {childs?.content}
                  </div>
                </div>
                {childs?.action}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </BaseDialog>
    </Transition>
  );
};

Dialog.Icon = ({ children, className }: IIconProps) => (
  <div
    className={clsx(
      'mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-green-400 text-[#2c2c2c]',
      className
    )}
  >
    {children}
  </div>
);

Dialog.Title = ({ children }: ITitleProps) => (
  <DialogTitle
    as="h3"
    className="text-base font-semibold leading-6 text-[--text-color]"
  >
    {children}
  </DialogTitle>
);

Dialog.Content = ({ children }: IContentProps) => (
  <div className="mt-2">{children}</div>
);

Dialog.Action = ({ children, className }: IActionProps) => (
  <div className={className}>{children}</div>
);

export default Dialog;

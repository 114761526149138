import clsx from 'clsx';
import type { PropsWithChildren } from 'react';

const Title = ({
  className,
  children
}: PropsWithChildren & { className?: string }) => (
  <h3 className={clsx('mb-2 py-1 font-bold', className)}>{children}</h3>
);

export default Title;

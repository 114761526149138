import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BlogApi } from '@/redux/api';
import type { PostType } from '@/types';

import { Title } from '../atoms';
import { FeaturePost } from '../molecules';

const FeaturesList = ({ specialPage = false }: { specialPage?: boolean }) => {
  const { t } = useTranslation();
  const { data: posts = {} } = BlogApi.useGetFeaturePostsQuery(undefined);

  return posts.data?.length ? (
    <div className="features-list">
      {specialPage ? null : <Title>{t('posts_feature')}</Title>}

      <div role="list">
        {posts.data
          ?.slice(0, 3)
          .map((post: PostType) => <FeaturePost key={post.id} post={post} />)}

        {specialPage
          ? posts.data
              ?.slice(3)
              .map((post: PostType) => (
                <FeaturePost key={post.id} post={post} />
              ))
          : null}
      </div>

      {specialPage ? null : (
        <div className="text-center mt-2">
          <Link
            to="/blog/feature"
            className="inline-block rounded bg-[--secondary-color] px-4 py-2 text-sm font-semibold text-[--text-color] shadow-sm"
          >
            {t('more')}
          </Link>
        </div>
      )}
    </div>
  ) : null;
};

export default FeaturesList;

import React from 'react';
import clsx from 'clsx';
import type { EmblaOptionsType } from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';

import { useSliderButtons, useSliderDots } from '@/hooks';

import { DotButton, NextButton, PrevButton } from '../atoms/slider-buttons';

interface IProps extends React.PropsWithChildren {
  className?: string;
  options?: EmblaOptionsType & {
    autoplay?: boolean;
    control?: Record<'dots' | 'buttons', boolean>;
  };
}

const Slider = ({ className, options, children }: IProps) => {
  const plugins = [];
  const control = options?.control;

  if (options?.autoplay) {
    plugins.push(Autoplay());
  }

  const [ref, api] = useEmblaCarousel(
    { ...options, direction: 'rtl' },
    plugins
  );

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useSliderDots(api);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = useSliderButtons(api);

  return (
    <div className={clsx('slider', className)}>
      <div className="slider-viewport" ref={ref}>
        <div className="slider-container">{children}</div>
      </div>

      {control?.buttons || control?.dots ? (
        <div className="slider-controls">
          {control?.buttons ? (
            <div className="slider-buttons">
              <PrevButton
                onClick={onPrevButtonClick}
                disabled={prevBtnDisabled}
              />
              <NextButton
                onClick={onNextButtonClick}
                disabled={nextBtnDisabled}
              />
            </div>
          ) : null}

          {control?.dots ? (
            <div className="slider-dots">
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={selectedIndex === index ? 'active' : ''}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

Slider.Item = ({
  className,
  children
}: Pick<IProps, 'className' | 'children'>) => (
  <div className={clsx('slider-item', className)}>{children}</div>
);

export default Slider;

import { Provider } from 'react-redux';

import { store } from '@/store';

import { TelegramProvider } from './telegram';

const ContextProvider = ({ children }: { children: React.ReactElement }) => (
  <Provider store={store}>
    <TelegramProvider>{children}</TelegramProvider>
  </Provider>
);

export default ContextProvider;

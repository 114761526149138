import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';

import { GameApi } from '@/redux/api';

import { useAppSelector } from './use-redux';

type Resources = { type: string; src: string }[];

interface IProps {
  game: Game;
  telegram: any;
  label: any;
  resources?: Resources;
  getGame: any;
  onClick: any;
}

const loadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(src);
    img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
  });
};

const loadAudio = (src: string) => {
  return new Promise((resolve, reject) => {
    const audio = new Audio();
    audio.src = src;
    audio.onloadeddata = () => resolve(src);
    audio.onerror = () => reject(new Error(`Failed to load audio: ${src}`));
  });
};

const loadResources = (resources: Resources) => {
  const promises = resources.map((resource) => {
    if (resource.type === 'image') {
      return loadImage(resource.src);
    }

    if (resource.type === 'audio') {
      return loadAudio(resource.src);
    }

    return Promise.reject(new Error(`Unknown resource type: ${resource.type}`));
  });

  return Promise.all(promises);
};

const useBet = ({
  game,
  telegram,
  label,
  resources,
  getGame,
  onClick
}: IProps) => {
  const [lock, setLock] = useState(0);
  const [hasRun, setHasRun] = useState(false);
  const [resourcesLoaded, setResourcesLoaded] = useState(!resources);

  const [base, betResult] = GameApi.useBetMutation();
  const { loading } = useAppSelector((state) => state.initial);

  const bet = useCallback(async () => {
    setLock(1);
    toast.dismiss();

    onClick();

    telegram.BackButton.hide();
    telegram.MainButton.setParams({
      is_active: false,
      color: '#575757',
      text_color: '#d1d1d1'
    });

    const { data } = await getGame();

    await base({
      game_id: data.data.id,
      game_schedule_id: data.additional.active_schedule.id
    });
  }, []);

  const handleUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (resources) {
      loadResources(resources)
        .then(() => {
          setResourcesLoaded(true);
        })
        .catch((error) => {
          console.error('Error loading resources:', error);
        });
    }

    return () => {
      telegram.MainButton.offClick(bet).hide();
    };
  }, []);

  useEffect(() => {
    if (hasRun) return;

    const button = telegram.MainButton;
    const isActive =
      game?.additional?.active_schedule?.active && resourcesLoaded && !loading;

    button
      .setParams({
        text: label,
        is_active: isActive,
        color: isActive ? '#33B249' : '#575757',
        text_color: isActive ? '#161616' : '#d1d1d1'
      })
      .onClick(bet)
      .show();

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isActive ? button.hideProgress() && setHasRun(true) : button.showProgress();
  }, [game, resourcesLoaded, loading]);

  useEffect(() => {
    if (lock) {
      window.addEventListener('beforeunload', handleUnload);
    } else {
      window.removeEventListener('beforeunload', handleUnload);
    }
  }, [lock]);

  const betButton = (
    <>
      {!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? (
        <button
          type="button"
          className={clsx(
            'absolute top-0 w-full p-[9px] z-50',
            telegram.MainButton.isActive ? 'bg-[#33B249]' : 'bg-[#575757]'
          )}
          disabled={!telegram.MainButton.isActive}
          onClick={bet}
        >
          {label}
        </button>
      ) : null}
      {lock ? <div className="fixed inset-0 z-[60]" /> : null}
    </>
  );

  return {
    betButton,
    betResult,
    unlock: () => setLock(0)
  };
};

export default useBet;

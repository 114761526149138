import { createContext } from 'react';

const getWebAppFromGlobal = () =>
  typeof window !== 'undefined' && window?.Telegram?.WebApp
    ? window.Telegram.WebApp
    : null;

const TelegramContext =
  createContext<ReturnType<typeof getWebAppFromGlobal>>(null);

export const TelegramProvider = ({
  children
}: {
  children: React.ReactElement;
}) => (
  <TelegramContext.Provider value={getWebAppFromGlobal()}>
    {children}
  </TelegramContext.Provider>
);

export default TelegramContext;

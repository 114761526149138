import { z } from 'zod';

export namespace Winners {
  const prize = z.object({
    title: z.string(),
    count: z.number()
  });

  export const schema = z.array(
    z.object({
      prizes: z.array(prize),
      user: z.object({
        id: z.number(),
        name: z.string(),
        avatar: z.string().nullish()
      })
    })
  );
}

export type WinnersType = z.infer<typeof Winners.schema>;

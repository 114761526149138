import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { GameApi } from '@/redux/api';

const GamesList = () => {
  const { t } = useTranslation();
  const { data: games = {} } = GameApi.useGetGamesQuery(undefined);

  return (
    <div role="list" className="games-list">
      {games.data?.map((game: Record<string, string>) => (
        <div key={game.id} className="games-list__item">
          <Link
            to={`/game/${game.key}`}
            className="group aspect-h-7 aspect-w-10"
          >
            {game.has_active_schedule ? (
              <span className="bg-green-500">{t('online')}</span>
            ) : (
              <span className="bg-red-500">{t('offline')}</span>
            )}
            <img src={game.cover} alt={game.name} />
            <button
              type="button"
              className="absolute inset-0 focus:outline-none"
            >
              <span className="sr-only">
                {t('game_view', { name: game.name })}
              </span>
            </button>
          </Link>
          <p className="pointer-events-none block truncate text-sm font-medium text-center p-2">
            {game.name}
          </p>
        </div>
      ))}
    </div>
  );
};

export default GamesList;

import type { RouteObject } from 'react-router-dom';

import { Blog, Dashboard, Game, Games, Home } from './components/pages';
import { Category, Feature, Post, Posts } from './components/pages/blog/index';
import { History, Profile } from './components/pages/dashboard/index';
import { Default, Error } from './components/templates';
import redirectToGame from './middlewares/redirect-to-game';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Default />,
    errorElement: <Error />,
    loader: redirectToGame,
    children: [
      { element: <Home />, index: true },
      {
        path: 'dashboard',
        element: <Dashboard />,
        children: [
          { element: <Profile />, index: true },
          { path: 'history', element: <History /> }
        ]
      },
      {
        path: 'blog',
        element: <Blog />,
        children: [
          { element: <Posts />, index: true },
          { path: 'feature', element: <Feature /> },
          { path: 'category/:id', element: <Category /> },
          { path: 'post/:id', element: <Post /> }
        ]
      },
      { path: 'games', element: <Games /> },
      {
        path: 'game/:key',
        element: <Game />
      }
    ]
  }
];

export default routes;

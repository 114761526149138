import { type BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegSave, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { ProfileImage } from '@/components/molecules';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { BaseApi } from '@/redux/api';
import { getUser, setUser } from '@/redux/slices/initial';

const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const [update, result] = BaseApi.useLazyProfileQuery();

  const handleSubmit = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target));

    await update(data).then((res) => {
      if (res.isSuccess) {
        toast.success(t('profile_updated'));

        dispatch(setUser({ ...res.data, token: user?.token }));
      }
    });
  };

  return (
    <div className="bg-[--secondary-color] p-4 rounded">
      <form method="post" onSubmit={handleSubmit}>
        <div className="text-center">
          <ProfileImage src={user?.avatar} alt={user?.first_name} />
        </div>

        <p className="mb-4 text-center">
          {user?.first_name}{' '}
          <span className="font-bold">(@{user?.username})</span>
        </p>

        <div className="rounded-md mb-1 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-[--border-color] focus-within:ring-2 focus-within:ring-indigo-600">
          <label
            htmlFor="first_name"
            className="block text-xs font-medium text-[--text-color]"
          >
            {t('first_name')}
          </label>
          <input
            id="first_name"
            name="first_name"
            type="text"
            defaultValue={user?.first_name}
            className="block w-full bg-transparent border-0 p-0 text-[--text-color] focus:ring-0 sm:text-sm sm:leading-6"
            required
          />
        </div>

        <div className="rounded-md mb-1 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-[--border-color] focus-within:ring-2 focus-within:ring-indigo-600">
          <label
            htmlFor="last_name"
            className="block text-xs font-medium text-[--text-color]"
          >
            {t('last_name')}
          </label>
          <input
            id="last_name"
            name="last_name"
            type="text"
            defaultValue={user?.last_name}
            className="block w-full bg-transparent border-0 p-0 text-[--text-color] focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>

        <button
          type="submit"
          className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 mt-2 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 overflow-hidden"
          disabled={result.isFetching || result.isLoading}
        >
          {result.isFetching || result.isLoading ? (
            <div className="absolute inset-0 flex items-center justify-center bg-indigo-400 z-10">
              <FaSpinner className="animate-spin-slow" />
            </div>
          ) : null}

          <FaRegSave size={18} />
          {t('save')}
        </button>
      </form>
    </div>
  );
};

export default Profile;

import { Link, Outlet } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

const Dashboard = () => {
  const links = [
    { href: '/dashboard', label: 'Profile' },
    { href: '/dashboard/history', label: 'History' }
  ];

  return (
    <>
      <div>
        <Menu>
          <MenuButton />
          <MenuItems anchor="bottom">
            {links.map((link) => (
              <MenuItem key={link.href}>
                <Link to={link.href}>{link.label}</Link>
              </MenuItem>
            ))}
          </MenuItems>
        </Menu>
      </div>

      <div className="container">
        <Outlet />
      </div>
    </>
  );
};

export default Dashboard;

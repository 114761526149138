import { configureStore } from '@reduxjs/toolkit';

import { BaseApi, BlogApi, GameApi } from './redux/api';
import { InitialReducer } from './redux/slices';

export const store = configureStore({
  reducer: {
    initial: InitialReducer,
    baseApi: BaseApi.reducer,
    blogApi: BlogApi.reducer,
    gameApi: GameApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      BaseApi.middleware,
      BlogApi.middleware,
      GameApi.middleware
    ),
  devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

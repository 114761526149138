import { redirect } from 'react-router-dom';

const redirectToGame = () => {
  const startapp = window.Telegram.WebApp.initDataUnsafe.start_param;

  if (!startapp) {
    return null;
  }

  window.Telegram.WebApp.initDataUnsafe.start_param = null;

  return redirect(`/game/${startapp?.split('_').pop()}`);
};

export default redirectToGame;

import { useTranslation } from 'react-i18next';

const History = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-[--secondary-color] rounded-lg px-2">
      <table className="bet-history">
        <thead>
          <tr>
            <th scope="col">{t('game')}</th>
            <th scope="col">{t('status')}</th>
            <th scope="col">{t('code')}</th>
            <th scope="col">{t('at')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>game</td>
            <td>title</td>
            <td>code</td>
            <td>at</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default History;

import { createApi } from '@reduxjs/toolkit/query/react';

import { validate } from '@/libs';
import { User } from '@/types';

import axiosBaseQuery from './axios-base-query';

export const api = createApi({
  reducerPath: 'baseApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    auth: build.query({
      query: (data) => ({ url: '/login', method: 'post', data }),
      transformResponse: validate(User.schema)
    }),
    profile: build.query({
      query: (data) => ({
        url: '/profile',
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data
      })
    }),
    getSlides: build.query({
      query: () => ({ url: '/slides', method: 'get' })
    })
  })
});

export const {
  reducer,
  middleware,
  useLazyAuthQuery,
  useLazyProfileQuery,
  useGetSlidesQuery
} = api;

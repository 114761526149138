import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { GameException } from '@/exceptions';
import { useBet, useLoader, useTelegram } from '@/hooks';
import { GameApi } from '@/redux/api';

import { Loading } from '../atoms';
import { Celebrate } from '../molecules';
import { BestBet, Dice, Wheel } from '../organisms';

const Game = () => {
  const { key } = useParams();
  const loading = useLoader();
  const telegram = useTelegram();
  const { t } = useTranslation();
  const [useGame, result] = GameApi.useGetGameMutation();
  const { data: game } = result;
  const [limitation, setLimitation] = useState<number | null>();
  const [celebrate, setCelebrate] = useState<boolean>(false);
  const getGame = () => useGame(key);
  const { betButton, betResult, unlock } = useBet({
    game,
    telegram,
    label: t(key ?? ''),
    getGame,
    onClick: () => {
      setCelebrate(false);
    }
  });

  if (game && game.additional.active_schedule === null) {
    throw new GameException();
  }

  useEffect(() => {
    telegram.BackButton.show();

    getGame();
  }, []);

  useEffect(() => {
    if (!game && !betResult.data) return;
    console.log(game, betResult.data);

    const limit =
      betResult.data?.additional.day_limit !== undefined
        ? betResult.data.additional.day_limit
        : game?.additional.day_limit;

    setLimitation(limit);
  }, [game, betResult]);

  const props: GameProps = {
    game,
    telegram,
    betResult,
    unlock,
    celebrate: () => setCelebrate(true)
  };

  const gameView = (() => {
    switch (key) {
      case 'spin':
        return <Wheel {...props} />;
      case 'dice':
        return <Dice {...props} />;
      default:
        return null;
    }
  })();

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="flex flex-col h-full">
        <div className="px-2 mb-2 flex items-center">
          <div>
            {t('day_limit')} :{' '}
            <span className="inline-block px-3 py-1 border border-[--border-color] bg-[--secondary-color] rounded-md">
              {(() => {
                if (typeof limitation === 'number') {
                  return limitation > 0
                    ? t('day_limit_count', { count: limitation })
                    : t('limit');
                }

                return t('unlimit');
              })()}
            </span>
          </div>
        </div>

        {gameView}
      </div>
      {betButton}
      {celebrate ? <Celebrate won={betResult.data} /> : null}
      <BestBet
        gameId={game?.data.id}
        scheduleId={game?.additional.active_schedule.id}
      />
    </>
  );
};

export default Game;

import { createApi } from '@reduxjs/toolkit/query/react';

import { validate } from '@/libs';
import { Game, Games, Winners } from '@/types';

import axiosBaseQuery from './axios-base-query';

export const api = createApi({
  reducerPath: 'gameApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getGame: build.mutation({
      query: (id) => ({ url: `/games/${id}`, method: 'get' }),
      transformResponse: validate(Game.schema)
    }),
    getGames: build.query({
      query: () => ({ url: '/games', method: 'get' }),
      transformResponse: validate(Games.schema)
    }),
    getWinners: build.mutation({
      query: ({ gameId, scheduleId }) => ({
        url: `/games/${gameId}/winners?game_schedule_id=${scheduleId}`,
        method: 'get'
      }),
      transformResponse: validate(Winners.schema)
    }),
    bet: build.mutation({
      query: (data) => ({ url: '/game-bet', method: 'post', data })
    })
  })
});

export const {
  reducer,
  middleware,
  useGetGameMutation,
  useGetGamesQuery,
  useGetWinnersMutation,
  useBetMutation
} = api;

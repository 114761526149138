import React from 'react';
import { HiHome } from 'react-icons/hi';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/">
            <HiHome size={20} />
          </Link>
        </li>
        {children}
      </ul>
    </div>
  );
};

Breadcrumb.Item = ({ children }: React.PropsWithChildren) => (
  <li>{children}</li>
);

export default Breadcrumb;

import { useTranslation } from 'react-i18next';

import { Avatar } from '../atoms';

interface IProps {
  histories: {
    user: Record<string, string>;
    prizes: Record<string, string>[];
  }[];
}

const BetHistory = ({ histories }: IProps) => {
  const { t } = useTranslation();

  return (
    <table className="bet-history">
      <thead>
        <tr>
          <th scope="col">{t('user_name')}</th>
          <th scope="col">{t('title')}</th>
          <th scope="col">{t('count')}</th>
        </tr>
      </thead>
      <tbody>
        {histories.map(({ user, prizes }) =>
          prizes.map((prize, index) => (
            <tr key={user.name}>
              {index === 0 ? (
                <td rowSpan={prizes.length}>
                  <div className="flex items-center gap-x-2">
                    <Avatar
                      className="w-10 h-10"
                      user={
                        {
                          first_name: user.name,
                          avatar: user.avatar
                        } as User
                      }
                    />
                    {user.name}
                  </div>
                </td>
              ) : null}
              <td>{prize.title}</td>
              <td>{prize.count}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default BetHistory;

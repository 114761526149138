export const vibrate = (type: 'win' | 'lose' | 'step') => {
  if (!('vibrate' in navigator)) return;

  // eslint-disable-next-line default-case
  switch (type) {
    case 'win':
      navigator.vibrate(250);
      break;
    case 'lose':
      navigator.vibrate(250);
      break;
    case 'step':
      navigator.vibrate(40);
      break;
  }
};

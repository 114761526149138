import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Breadcrumb } from '@/components/atoms';
import { PostCard } from '@/components/molecules';
import { useInfiniteScroll } from '@/hooks';
import { BlogApi } from '@/redux/api';
import type { PostType } from '@/types';

const Category = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [getCategory, result] = BlogApi.useLazyGetCategoryQuery();
  const [category, setCategory] = useState(result.data);
  const { ref } = useInfiniteScroll(
    {
      isReady: result.isSuccess,
      hasMore: !!category?.links.next_page_url,
      next: (page: number) => {
        getCategory({ id, page });
      }
    },
    [category?.links.next_page_url]
  );

  useEffect(() => {
    getCategory({ id });
  }, []);

  useEffect(() => {
    if (!result.data) return;

    const { data, links } = result.data;

    setCategory({
      data: [...(category?.data ?? []), ...data],
      links
    });
  }, [result.data]);

  return category?.data?.length ? (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/blog">{t('blog')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{category?.data[0].category.name}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="post-list">
        {category?.data?.map((post: PostType, i: number) => (
          <PostCard
            key={post.id}
            ref={category?.data?.length === i + 1 ? ref : null}
            post={post}
          />
        ))}
        {result.isFetching || result.isLoading ? (
          <div className="bg-[--secondary-color] rounded-lg overflow-hidden">
            <div className="w-full h-60 bg-[#bdbdbd] rounded-lg" />
            <div className="px-2 py-3">
              <div className="flex justify-between h-3">
                <span className="w-28 bg-[#bdbdbd]" />
                <span className="w-20 bg-[#bdbdbd]" />
              </div>
              <div className="w-2/3 h-4 mt-4 bg-[#bdbdbd]" />
              <div className="w-full h-10 mt-2 bg-[#bdbdbd]" />
            </div>
          </div>
        ) : null}
      </div>
    </>
  ) : (
    <div>{t('empty_category')}</div>
  );
};

export default Category;

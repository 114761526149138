import { Outlet } from 'react-router-dom';

import { useTelegram } from '@/hooks';

const blog = () => {
  const telegram = useTelegram();

  telegram.BackButton.show();

  return (
    <div id="blog" className="container">
      <Outlet />
    </div>
  );
};

export default blog;

import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import type { PostType } from '@/types';

interface IProps extends React.ComponentProps<'div'> {
  post: PostType;
}

const PostCard = forwardRef<HTMLDivElement, IProps>(({ post }, ref) => {
  return (
    <div className="post-card" ref={ref}>
      <Link to={`/blog/post/${post.id}`}>
        <img alt={post.title} src={post.cover} />
      </Link>
      <div className="info">
        <div>
          <Link to={`/blog/category/${post.category.id}`}>
            {post.category.name}
          </Link>
          <span>{post.created_at}</span>
        </div>
        <Link to={`/blog/post/${post.id}`}>
          <h4>{post.title}</h4>
        </Link>
        <p>{post.short_description}</p>
      </div>
    </div>
  );
});

export default PostCard;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef } from 'react';
import { HiOutlinePencil } from 'react-icons/hi';

const ProfileImage = ({ src, alt }: { src?: string; alt?: string }) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const readURL = (input: Event) => {
    const file = (input.target as HTMLInputElement).files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (imgRef.current) {
          imgRef.current.src = e.target?.result as string;
        }
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const input = document.getElementById('user_image') as HTMLInputElement;
    input.addEventListener('change', readURL);

    return () => {
      input.removeEventListener('change', readURL);
    };
  }, []);

  return (
    <div className="relative inline-block mb-2">
      <div className="absolute top-2 right-2">
        <input
          type="file"
          name="user_image"
          id="user_image"
          accept=".png, .jpg, .jpeg"
          className="hidden"
        />
        <label
          htmlFor="user_image"
          className="inline-block p-2 bg-[--border-color] rounded-2xl shadow"
        >
          <HiOutlinePencil />
        </label>
      </div>
      <img
        ref={imgRef}
        src={src ?? `https://ui-avatars.com/api?name=${alt}`}
        alt={alt}
        className="w-36 h-36 rounded-full bg-[--border-color] p-1 object-cover shadow"
      />
    </div>
  );
};

export default ProfileImage;

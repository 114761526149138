import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { hasLoading } from '@/redux/slices/initial';

import { useAppDispatch, useAppSelector } from './use-redux';
import useTelegram from './use-telegram';

const useLoader = () => {
  const telegram = useTelegram();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.initial);
  const location = useLocation();
  const [visitedPages, setVisitedPages] = useState<string[]>([]);

  const handleLoading = () => {
    setTimeout(() => {
      dispatch(hasLoading(false));
    }, 5000);
  };

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath === '/') {
      telegram.BackButton.hide();
    }

    if (visitedPages.includes(currentPath)) {
      return () => {};
    }

    if (!loading) {
      dispatch(hasLoading(true));
    }

    if (document.readyState === 'complete') {
      handleLoading();
    } else {
      window.addEventListener('load', handleLoading);
    }

    setVisitedPages([...visitedPages, currentPath]);

    return () => window.removeEventListener('load', handleLoading);
  }, [location]);

  return loading;
};

export default useLoader;

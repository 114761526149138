import { z } from 'zod';

export namespace Game {
  export const prize = z.object({
    id: z.number(),
    key: z.string(),
    label: z.string(),
    color: z.string()
  });

  export const data = z.object({
    id: z.number(),
    key: z.string(),
    name: z.string(),
    cover: z.string().url(),
    active: z.number(), // FIXME: It should be boolean, not number
    has_active_schedule: z.boolean(),
    created_at: z.string()
  });

  export const additional = z.object({
    active_schedule: z
      .object({
        id: z.number(),
        game_id: z.number(),
        active: z.boolean(),
        start_date: z.string(),
        end_date: z.string(),
        created_at: z.string(),
        rules: z.object({
          day_limit: z.number(),
          week_limit: z.number(),
          month_limit: z.number()
        }),
        prizes: z.array(prize)
      })
      .nullish(),
    day_limit: z.number().nullable()
  });

  export const schema = z.object({
    data,
    additional
  });
}

export type GameType = z.infer<typeof Game.schema>;

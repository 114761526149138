import { useCallback, useEffect, useState } from 'react';
import type { EmblaCarouselType } from 'embla-carousel';

interface IUseSliderDots {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
}

const useSliderDots = (api: EmblaCarouselType | undefined): IUseSliderDots => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!api) return;

      api.scrollTo(index);
    },
    [api]
  );

  const onInit = useCallback((_api: EmblaCarouselType) => {
    setScrollSnaps(_api.scrollSnapList());
  }, []);

  const onSelect = useCallback((_api: EmblaCarouselType) => {
    setSelectedIndex(_api.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!api) return;

    onInit(api);
    onSelect(api);
    api.on('reInit', onInit);
    api.on('reInit', onSelect);
    api.on('select', onSelect);
  }, [api, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick
  };
};

export default useSliderDots;

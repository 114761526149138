import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

import { useAppSelector } from '@/hooks';
import { getUser } from '@/redux/slices/initial';

import { Avatar } from '../atoms';

const Profile = () => {
  const { t } = useTranslation();
  const user = useAppSelector(getUser);
  const links = [
    { href: '/dashboard', label: t('profile') },
    { href: '/dashboard/history', label: t('history') }
  ];

  return (
    <div>
      <Menu>
        <MenuButton className="profile">
          <Avatar user={user as User} />
          <span>{user?.first_name}</span>
        </MenuButton>
        <MenuItems className="w-36 bg-[--secondary-color] mt-2 rounded divide-y divide-[--border-color] shadow">
          {links.map((link) => (
            <MenuItem key={link.href}>
              <Link to={link.href} className="block p-2">
                {link.label}
              </Link>
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
    </div>
  );
};

export default Profile;

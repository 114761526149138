import React, { Fragment, useCallback, useState } from 'react';
import clsx from 'clsx';
import { HiChevronDown, HiX } from 'react-icons/hi';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild
} from '@headlessui/react';

interface IProps extends React.PropsWithChildren {
  direction?: 'rtl' | 'ltr' | 'btt';
}

const SlideOver = ({ children, direction = 'rtl' }: IProps) => {
  const [open, setOpen] = useState(false);

  const { enter, leave } = (() => {
    switch (direction) {
      case 'ltr':
        return {
          enter: { from: '-translate-x-full', to: 'translate-x-0' },
          leave: { from: 'translate-x-0', to: '-translate-x-full' }
        };
      case 'btt':
        return {
          enter: {
            from: 'translate-y-full',
            to: 'translate-y-[--header-height]'
          },
          leave: {
            from: 'translate-y-[--header-height]',
            to: 'translate-y-full'
          }
        };
      default:
        return {
          enter: { from: 'translate-x-full', to: 'translate-x-0' },
          leave: { from: 'translate-x-0', to: 'translate-x-full' }
        };
    }
  })();

  const onTrigger = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <>
      {React.Children.map(children as React.ReactElement, (child) =>
        child.type === SlideOver.Trigger ? (
          React.cloneElement(child, {
            onClick: () => {
              child.props?.onClick();
              onTrigger();
            }
          })
        ) : (
          <Transition show={open} as={Fragment}>
            <Dialog className="slide-over" onClose={setOpen}>
              <TransitionChild
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className={clsx(
                    'overlay',
                    direction === 'btt' ? 'hidden' : ''
                  )}
                />
              </TransitionChild>

              <div className="panel-overlay">
                <div>
                  <div
                    className={clsx(
                      'panel-wrapper',
                      direction === 'btt' ? 'hidden !pl-0' : '',
                      direction === 'ltr' ? '!pl-0 pr-10' : ''
                    )}
                  >
                    <TransitionChild
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom={enter.from}
                      enterTo={enter.to}
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom={leave.from}
                      leaveTo={leave.to}
                    >
                      <DialogPanel className="slide-panel">
                        {direction === 'btt' ? (
                          <TransitionChild
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="top-0"
                            enterTo="-top-[24px]"
                            leave="ease-in-out duration-500"
                            leaveFrom="-top-[24px]"
                            leaveTo="top-0"
                          >
                            <button
                              type="button"
                              onClick={() => setOpen(false)}
                              className="absolute left-1/2 flex justify-center bg-[--primary-color] w-1/4 -translate-x-1/2 rounded-t-2xl"
                            >
                              <span className="absolute -inset-0.5" />
                              <span className="sr-only">Close panel</span>
                              <HiChevronDown
                                className="w-6 h-6"
                                aria-hidden="true"
                              />
                            </button>
                          </TransitionChild>
                        ) : (
                          <TransitionChild
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div
                              className={clsx(
                                'absolute left-0 top-0 flex pt-4',
                                direction === 'ltr'
                                  ? 'left-auto right-0 -mr-8 pl-2 sm:pl-4 sm:-mr-10'
                                  : '-ml-8 pr-2 sm:pr-4 sm:-ml-10'
                              )}
                            >
                              <button
                                type="button"
                                className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setOpen(false)}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <HiX className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </TransitionChild>
                        )}
                        {child}
                      </DialogPanel>
                    </TransitionChild>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition>
        )
      )}
    </>
  );
};

SlideOver.Trigger = ({
  children,
  onClick
}: {
  children: React.ReactElement;
  onClick?(): void;
}) => React.cloneElement(children, { onClick });

SlideOver.Content = ({
  children,
  className
}: React.PropsWithChildren & {
  className?: string;
}) => <div className={clsx('slide-panel-content', className)}>{children}</div>;

export default SlideOver;
